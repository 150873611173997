import Core from "~/services/Core";
import { Experience } from "@ogury/motionly-ws-api/ws";
class MetaService {
  canUpgrade(experience) {
    const template = Core.getCurrentTemplate();
    console.log(experience, template);
    // noinspection JSUnresolvedVariable
    if (template !== undefined) {
      const currentVersion = template.version;
      return (
        template.versions?.indexOf(currentVersion) !== template?.versions?.length - 1 &&
        experience.state === Experience.StateEnum.Draft
      );
    }
    return false;
  }
}

export default new MetaService();
