import { ColorPicker, Modal, ModalClosableFooter, Typography } from "@ogury/design-system";
import style from "app/inputs/ui/InputSpriteUi/InputSpriteUi.module.scss";
import { BinaryUiModalDialog, Button, IconNames, Icons, Image, NumberEdit } from "components";
import i18n from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Api from "~/util/Api";
import { toStringWithBytes, toStringWithInteger } from "~/util/Helpers";
import { computeImageWeight, convertImageQuality, isDataUrl, loadImage } from "~/util/InputsComputer";

const DEFAULT_IMAGE_QUALITY = 90;

const InputImageCrop = ({
  dataUrl,
  updateLatestCroppedImageDataUrl,
  mimeType,
  minimumRatio,
  maximumRatio, // TODO: maximumRatio is not used anymore, we now only uses minimumRatio see when to remove this props
  setCanCrop,
}) => {
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const imageRef = useRef();

  useEffect(() => {
    const { naturalWidth: width, naturalHeight: height } = imageRef.current;

    const croppedImage = {
      width: (crop.width * width) / 100,
      height: (crop.height * height) / 100,
      x: (crop.x * width) / 100,
      y: (crop.y * height) / 100,
    };

    // Even though we round the percent crop values, we still need to round the actual values because the image itself can have a decimal width/height
    const ratio = croppedImage.width / croppedImage.height;
    if (minimumRatio) {
      if (ratio > minimumRatio) {
        croppedImage.width = Math.round(croppedImage.height * minimumRatio);
      } else {
        croppedImage.height = Math.round(croppedImage.width / minimumRatio);
      }

      // Often, the image is not exactly the ratio we want, so we need to crop a second time
      // This happens especially when the image is already small
      const newRatio = croppedImage.width / croppedImage.height;
      if (newRatio > minimumRatio) {
        croppedImage.width = Math.floor(croppedImage.height * minimumRatio);
      } else {
        croppedImage.height = Math.floor(croppedImage.width / minimumRatio);
      }
    }

    const imageCroppedDataUrl = convertImageQuality(
      imageRef.current,
      mimeType,
      undefined,
      DEFAULT_IMAGE_QUALITY,
      croppedImage.width,
      croppedImage.height,
      croppedImage.x,
      croppedImage.y,
      croppedImage.width,
      croppedImage.height
    );
    updateLatestCroppedImageDataUrl(imageCroppedDataUrl);
  }, [crop, dataUrl]);

  return (
    <div className={style.cropContainer}>
      <ReactCrop
        crop={crop}
        aspect={minimumRatio}
        style={{ maxHeight: "75vh" }}
        minWidth={50}
        minHeight={50}
        className={style.reactCropOverride}
        onChange={(_, percentCrop) => {
          // We need to round the values to prevent aspect ratio shifting, because in the end, canvas.width and canvas.height needs to be integers
          percentCrop.width = Math.round(percentCrop.width);
          percentCrop.height = Math.round(percentCrop.height);

          setCrop(percentCrop);
          const canCrop = percentCrop.width !== 100 || percentCrop.height !== 100;
          setCanCrop(canCrop);
        }}
        ruleOfThirds
        keepSelection
      >
        <Image
          withCheckboardBackground
          ref={imageRef}
          src={dataUrl}
          alt="image"
          crossOrigin="anonymous"
          onError={async event => {
            /**
             * @type {HTMLImageElement}
             */
            const image = event.target;
            // We check whether the issue comes from a CORS restriction
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            try {
              context.drawImage(image, 0, 0);
              context.getImageData(0, 0, 1, 1);
            } catch (error) {
              // This seems to be a CORS issue, hence we resort to a proxy
              const blob = await Api.useProxy(src);
              const fileReader = new FileReader();
              fileReader.onload = function (event) {
                // noinspection JSValidateTypes
                image.src = event.target.result;
              };
              fileReader.readAsDataURL(blob);
            }
          }}
          onLoad={event => {
            // https://github.com/DominicTobias/react-image-crop/issues/319#issuecomment-570915030
            event.target.dispatchEvent(new Event("medialoaded", { bubbles: true }));

            const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

            if (typeof minimumRatio === "undefined") {
              setCrop({
                unit: "%",
                width: 100,
                height: 100,
                x: 0,
                y: 0,
              });
              setCanCrop(false);
              return;
            }

            const percentCrop = centerCrop(
              makeAspectCrop(
                {
                  unit: "%",
                  height: 100,
                },
                minimumRatio,
                width,
                height
              ),
              width,
              height
            );

            // We need to round the values to prevent aspect ratio shifting, because in the end, canvas.width and canvas.height needs to be integers
            percentCrop.width = Math.round(percentCrop.width);
            percentCrop.height = Math.round(percentCrop.height);

            setCrop(percentCrop);
            const needsCropping = percentCrop.width !== 100 || percentCrop.height !== 100;
            setCanCrop(needsCropping);
          }}
        />
      </ReactCrop>
    </div>
  );
};

const InputImageResize = ({ isOpen, dataUrl, mimeType, onClose, onSuccess }) => {
  const [isOk, setOk] = useState(false);
  const [scale, setScale] = useState();
  const [image, setImage] = useState(/** @type HTMLImageElement */ undefined);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();

  const [t] = useTranslation();

  const scaleImage = useCallback(
    (image, scale) => {
      return convertImageQuality(
        image,
        mimeType,
        undefined,
        DEFAULT_IMAGE_QUALITY,
        (image.naturalWidth * scale) / 100,
        (image.naturalHeight * scale) / 100,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
    },
    [mimeType]
  );

  useEffect(() => {
    const run = async () => {
      let newImage;
      try {
        newImage = await loadImage(dataUrl);
      } catch (error) {
        console.error("Could not turn the data URI into an image", error);
        return;
      }
      setImage(newImage);
      setScale(100);
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [dataUrl]);

  useEffect(() => {
    if (scale !== undefined) {
      setWidth(Math.ceil(image.naturalWidth * (scale / 100)));
      setHeight(Math.ceil(image.naturalHeight * (scale / 100)));
      const dataUrl = scaleImage(image, scale);
      setWeight(computeImageWeight(dataUrl));
    }
  }, [scale]);
  console.log(isOpen);

  return (
    <Modal
      open={isOpen}
      width="20%"
      onCancel={onClose}
      title={t("notification.editImage.title")}
      footer={
        <ModalClosableFooter
          buttonLabel={t("components.OkCancelButtons.cancel")}
          actions={
            <Button
              onClick={() => {
                onSuccess(scaleImage(image, scale));
              }}
              disabled={isOk === false}
            >
              {t("inputsUI.button.resize")}
            </Button>
          }
        />
      }
    >
      <div>
        <div>
          {scale !== undefined && (
            <>
              <Typography.P2Regular>Scale</Typography.P2Regular>
              <NumberEdit
                style={{ width: "100%" }}
                formatter={value => value + " %"}
                minimum={1}
                maximum={100}
                step={5}
                digits={2}
                value={scale}
                onChange={value => {
                  if (value > 0) {
                    if (value !== scale) {
                      setScale(value);
                      setOk(value !== 100);
                    }
                  } else {
                    return scale;
                  }
                }}
              />
            </>
          )}
        </div>
        {image !== undefined && width !== undefined && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Typography.P2Regular>{t("inputs.label.dimensions")}</Typography.P2Regular>
            <div className={style.dimensionsContainer}>
              <NumberEdit
                style={{ width: "100%" }}
                minimum={1}
                maximum={image.naturalWidth}
                step={1}
                digits={-1}
                value={width}
                onChange={value => {
                  value = Math.min(value, image.naturalWidth);
                  const newScale = (value / image.naturalWidth) * 100;
                  if (newScale !== scale) {
                    setScale(newScale);
                    setOk(newScale !== 100);
                  }
                  return value;
                }}
              />
              <Typography.P2Regular style={{ marginRight: 10, marginLeft: 10 }}>
                {t("inputs.label.x")}
              </Typography.P2Regular>
              <NumberEdit
                style={{ width: "100%" }}
                minimum={1}
                maximum={image.naturalHeight}
                step={1}
                digits={-1}
                value={height}
                onChange={value => {
                  value = Math.min(value, image.naturalHeight);
                  const newScale = (value / image.naturalHeight) * 100;
                  if (newScale !== scale) {
                    setScale(newScale);
                    setOk(newScale !== 100);
                  }
                  return value;
                }}
              />
            </div>
          </div>
        )}

        {width !== undefined && height !== undefined && weight !== undefined && (
          <>
            <span style={{ marginRight: 10 }}>
              <Typography.P2Regular style={{ marginRight: 10 }}>{t("inputs.label.pixels")}:</Typography.P2Regular>
              <Typography.P2Regular>{toStringWithInteger(width * height)}</Typography.P2Regular>
            </span>
            <span>
              <Typography.P2Regular style={{ marginRight: 10 }}>{t("inputs.label.weight")}:</Typography.P2Regular>
              <Typography.P2Regular>{toStringWithBytes(weight)}</Typography.P2Regular>
            </span>
          </>
        )}
      </div>
    </Modal>
  );
};

const InputImageToJpeg = ({ isOpen, dataUrl, onClose, onSuccess }) => {
  const [color, setColor] = useState("#FFFFFF");
  const [t] = useTranslation();

  const handleChangeComplete = value => {
    setColor(value.toHexString());
  };
  return (
    <Modal
      open={isOpen}
      width={376}
      onCancel={onClose}
      title={t("notification.editImage.title")}
      footer={
        <ModalClosableFooter
          buttonLabel={t("components.OkCancelButtons.cancel")}
          actions={
            <Button
              onClick={async () => {
                const image = await loadImage(dataUrl);
                const newDataUrl = convertImageQuality(
                  image,
                  jpegMimeType,
                  color,
                  DEFAULT_IMAGE_QUALITY,
                  image.naturalWidth,
                  image.naturalHeight,
                  0,
                  0,
                  image.naturalWidth,
                  image.naturalHeight
                );
                onSuccess(newDataUrl);
              }}
            >
              {t("inputsUI.button.apply")}
            </Button>
          }
        />
      }
    >
      <div style={{ height: 420 }}>
        <Typography.P2Regular>{t("inputs.label.backgroundColor")}</Typography.P2Regular>
        <ColorPicker open disabledAlpha allowClear value={color} onChangeComplete={handleChangeComplete} />
      </div>
    </Modal>
  );
};

const jpegMimeType = "image/jpeg";
const pngMimeType = "image/png";

const ImageEdit = ({
  urlOrDataUrl,
  mimeType,
  authorizedMimeTypes,
  minimumRatio,
  maximumRatio,
  isOpen,
  setOpen,
  setDataUrl,
}) => {
  const [initialDataUrl, setInitialDataUrl] = useState();
  const [internalDataUrl, setInternalDataUrl] = useState();
  const [internalMimeType, setInternalMimeType] = useState(mimeType);
  const [isChanged, setChanged] = useState(false);
  const [isResize, setResize] = useState(false);
  const [isJpeg, setJpeg] = useState(false);
  const [canCrop, setCanCrop] = useState(false);

  const setInternalDataUrlAndChange = useCallback(dataUrl => {
    setInternalDataUrl(dataUrl);
    setChanged(true);
  }, []);

  const latestCroppedImageDataUrl = useRef();
  const updateLatestCroppedImageDataUrl = details => {
    latestCroppedImageDataUrl.current = details;
  };

  const handleCrop = () => {
    if (!latestCroppedImageDataUrl.current) {
      return;
    }
    setInternalDataUrlAndChange(latestCroppedImageDataUrl.current);
    setCanCrop(false);
  };

  useEffect(() => {
    const run = async () => {
      if (urlOrDataUrl === undefined) {
        return;
      }
      if (isDataUrl(urlOrDataUrl) === false) {
        let image;
        try {
          image = await loadImage(urlOrDataUrl);
        } catch (error) {
          console.error("Could not turn the data URI '" + urlOrDataUrl + "' into an image", error);
          return;
        }
        if (initialDataUrl === undefined) {
          setInitialDataUrl(image.src);
        }
        setInternalDataUrl(image.src);
      } else {
        if (initialDataUrl === undefined) {
          setInitialDataUrl(urlOrDataUrl);
        }
        setInternalDataUrl(urlOrDataUrl);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [urlOrDataUrl]);

  useEffect(() => {
    setInternalMimeType(mimeType);
  }, [mimeType]);

  return (
    <>
      {isResize === true && (
        <InputImageResize
          isOpen={isResize}
          dataUrl={internalDataUrl}
          mimeType={internalMimeType}
          onClose={() => setResize(false)}
          onSuccess={dataUrl => {
            if (dataUrl !== undefined) {
              setInternalDataUrlAndChange(dataUrl);
            }
            setResize(false);
            setCanCrop(canCrop === true || dataUrl !== undefined);
          }}
        />
      )}
      {isJpeg && (
        <InputImageToJpeg
          isOpen={isJpeg}
          dataUrl={internalDataUrl}
          onClose={() => setJpeg(false)}
          onSuccess={async dataUrl => {
            if (dataUrl !== undefined) {
              setInternalDataUrlAndChange(dataUrl);
              setInternalMimeType(jpegMimeType);
            }
            setJpeg(false);
            setCanCrop(canCrop === true || dataUrl !== undefined);
          }}
        />
      )}
      <Button
        data-testid="btn-crop-image"
        type="tertiary"
        size="small"
        onClick={() => setOpen(true)}
        icon={<Icons name={IconNames.Pencil.type} />}
        iconPosition="iconOnly"
      />
      {isOpen === true && (
        <BinaryUiModalDialog
          title={i18n.t("notification.editImage.title")}
          isOpen={isOpen}
          setOpen={setOpen}
          isChanged={isChanged}
          onApplyUrlOrDataUrl={async () => {
            await setDataUrl(internalDataUrl);
            setInitialDataUrl(internalDataUrl);
            setChanged(false);
          }}
          onRevertUrlOrDataUrl={() => {
            setInternalDataUrl(initialDataUrl);
            setInternalMimeType(mimeType);
            setChanged(false);
          }}
          buttons={
            <div className={style.buttonContainer}>
              <Button onClick={handleCrop} type="secondary" style={{ marginLeft: 10 }} disabled={!canCrop}>
                {i18n.t("inputsUI.button.sprite.crop")}
              </Button>
              <Button
                type="secondary"
                style={{ marginLeft: 10 }}
                disabled={canCrop}
                onClick={async () => {
                  setResize(true);
                }}
              >
                {i18n.t("inputsUI.button.sprite.resize")}
              </Button>
              {authorizedMimeTypes.indexOf(jpegMimeType) !== -1 && internalMimeType !== jpegMimeType && (
                <Button
                  type="secondary"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setJpeg(true);
                  }}
                >
                  {i18n.t("inputsUI.button.sprite.JPEG")}
                </Button>
              )}
            </div>
          }
        >
          <InputImageCrop
            dataUrl={internalDataUrl}
            mimeType={internalMimeType}
            minimumRatio={minimumRatio}
            maximumRatio={maximumRatio}
            setCanCrop={setCanCrop}
            updateLatestCroppedImageDataUrl={updateLatestCroppedImageDataUrl}
          />
        </BinaryUiModalDialog>
      )}
    </>
  );
};

export default ImageEdit;
